import React from 'react';
import { Switch } from 'antd';

import styles from './StatusBlock.module.scss';

interface StatusBlockProps {
  value: boolean;
  onChangeStatus: () => void;
}

export default function StatusBlock({ value, onChangeStatus }: StatusBlockProps) {
  return (
    <div className={styles.status}>
      <div className={styles.status_title}>Status</div>
      <div className={styles.status_switch}>
        <span className={styles.status_switch_label}>Off</span>
        <Switch value={value} onChange={onChangeStatus} />
        <span>On</span>
      </div>
    </div>
  );
}
