import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'antd';

import CustomSelect from '../../../../components/CustomSelect/CustomSelect';
import DoubleInputField from '../../../../components/DoubleInputField/DoubleInputField';
import InputField from '../../../../components/InputField/InputField';
import { useAppDispatch } from '../../../../store';
import { editAsset, getAssets } from '../../../../store/assets/asyncThunks/slice';
import { DataType } from '../types';

import ImageBlock from './ImageBlock/ImageBlock';
import StatusBlock from './StatusBlock/StatusBlock';

import styles from './EditAssetModal.module.scss';

const modalStyles = {
  content: {
    padding: 0,
    width: 600,
  },
  header: {
    padding: '16px 24px',
    borderBottom: '1px solid #F0F0F0',
    marginBottom: 0,
  },
};

interface EditAssetModalProps {
  isModalOpen: boolean;
  editAssetData: DataType | null;
  handleCancel: () => void;
}

interface FormValues {
  name: string;
  symbol: string;
  wagerMin: number;
  wagerMax: number;
  multiplierMin: number;
  multiplierMax: number;
  takeProfitLimit: number;
  stopLossLimit: number;
  flatFeePercent: number;
  pnlFeePercent: number;
  fundingFeePercent: number;
  fundingFeeTimeframe: number;
}

export default function EditAssetModal({ isModalOpen, editAssetData, handleCancel }: EditAssetModalProps) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [statusValue, setStatusValue] = useState<boolean>(false);

  useEffect(() => {
    if (editAssetData) {
      form.setFieldsValue({
        ...editAssetData,
        isActive: editAssetData.isActive,
      });
      setStatusValue(editAssetData.isActive || false);
    }
  }, [form, editAssetData]);

  const onSubmit = async (values: FormValues) => {
    const { id, type } = editAssetData || {};
    await dispatch(
      editAsset({
        assetId: id as number,
        body: { ...values, icon: editAssetData?.icon, type, isActive: statusValue },
      }),
    );
    await dispatch(getAssets());
    handleCancel();
  };

  const handleChangeStatus = () => {
    setStatusValue((prev) => !prev);
  };

  const requiredLabel = (text: string) => (
    <div>
      {text} <span style={{ color: '#ff4d4f' }}>*</span>
    </div>
  );

  return (
    <Modal title='Edit asset' open={isModalOpen} onCancel={handleCancel} footer={null} styles={modalStyles}>
      <Form onFinish={onSubmit} className={styles.form} requiredMark={false} form={form}>
        <div className={styles.main}>
          <div className={styles.item}>
            <CustomSelect
              label={requiredLabel('Asset name')}
              name='symbol'
              placeholder='Enter asset name'
              options={[
                { value: 'BTCUSD', label: 'BTCUSD' },
                { value: 'ETHUSD', label: 'ETHUSD' },
              ]}
              rules={[{ required: true, message: 'Please enter asset name' }]}
            />
            <InputField
              label={requiredLabel('Display name')}
              name='name'
              rules={[{ required: true, message: 'Please enter display name' }]}
              placeholder='Enter display name'
            />
          </div>
          <div className={styles.item}>
            <DoubleInputField
              label={requiredLabel('Wager (min/max)')}
              name1='wagerMin'
              name2='wagerMax'
              rules1={[{ required: true, message: 'Please enter wager min' }]}
              rules2={[{ required: true, message: 'Please enter wager max' }]}
              placeholder1='Min'
              placeholder2='Max'
            />
            <DoubleInputField
              label={requiredLabel('Multiplier (min/max)')}
              name1='multiplierMin'
              name2='multiplierMax'
              rules1={[{ required: true, message: 'Please enter multiplier min' }]}
              rules2={[{ required: true, message: 'Please enter multiplier max' }]}
              placeholder1='Min'
              placeholder2='Max'
            />
          </div>
          <div className={styles.item}>
            <InputField
              label={requiredLabel('Limit (take profit)')}
              name='takeProfitLimit'
              rules={[{ required: true, message: 'Please enter limit (take profit)' }]}
              placeholder='Enter limit'
            />
            <InputField
              label={requiredLabel('Limit (stop loss)')}
              name='stopLossLimit'
              rules={[{ required: true, message: 'Please enter limit (stop loss)' }]}
              placeholder='Enter limit'
            />
          </div>
          <div className={styles.item}>
            <InputField
              label={requiredLabel('Flat Fee')}
              name='flatFeePercent'
              rules={[{ required: true, message: 'Please enter Flat fee' }]}
              placeholder='0.00%'
            />
            <InputField
              label={requiredLabel('PNL Fee')}
              name='pnlFeePercent'
              rules={[{ required: true, message: 'Please enter PNL fee' }]}
              placeholder='0.00%'
            />
          </div>
          <div className={styles.item}>
            <InputField
              label={requiredLabel('Funding Fee Percent')}
              name='fundingFeePercent'
              rules={[{ required: true, message: 'Please enter Funding Fee Percent' }]}
              placeholder='Enter %'
            />
            <InputField
              label={requiredLabel('Funding Fee Timeframe')}
              name='fundingFeeTimeframe'
              rules={[{ required: true, message: 'Please enter Funding Fee Timeframe' }]}
              placeholder='Enter N of hours'
            />
          </div>
          <div className={styles.item}>
            <ImageBlock />
            <StatusBlock value={statusValue} onChangeStatus={handleChangeStatus} />
          </div>
        </div>
        <div className={styles.footer}>
          <Button key='cancel' onClick={handleCancel} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button key='ok' type='primary' htmlType='submit' className={styles.okButton}>
            Edit asset
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
