import React, { useState } from 'react';
import { Button, Form, Modal } from 'antd';

import CustomSelect from '../../../../components/CustomSelect/CustomSelect';
import DoubleInputField from '../../../../components/DoubleInputField/DoubleInputField';
import InputField from '../../../../components/InputField/InputField';
import { useAppDispatch } from '../../../../store';
import { createAsset, getAssets } from '../../../../store/assets/asyncThunks/slice';

import ImageBlock from './ImageBlock/ImageBlock';
import StatusBlock from './StatusBlock/StatusBlock';

import styles from './AddAssetModal.module.scss';

const modalStyles = {
  content: {
    padding: 0,
    width: 600,
  },
  header: {
    padding: '16px 24px',
    borderBottom: '1px solid #F0F0F0',
    marginBottom: 0,
  },
};

interface AddAssetModalProps {
  isOpenModal: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}

interface FormValues {
  id: number;
  name: string;
  symbol: string;
  type: string;
  isActive: boolean;
  icon: string;
  multiplierMin: number;
  multiplierMax: number;
  wagerMin: number;
  wagerMax: number;
  takeProfitLimit: number;
  stopLossLimit: number;
  flatFeePercent: number;
  pnlFeePercent: number;
  fundingFeePercent: number;
  fundingFeeTimeframe: number;
}

export default function AddAssetModal({ isOpenModal, handleOk, handleCancel }: AddAssetModalProps) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const [status, setStatus] = useState<boolean>(false);

  const handleToggle = () => {
    setStatus((prev) => !prev);
  };

  const onSubmit = async (values: FormValues) => {
    const requestBody = {
      name: values.name,
      symbol: values.symbol,
      type: 'CRYPTO',
      isActive: status,
      icon: 'icon.png',
      multiplierMin: values.multiplierMin,
      multiplierMax: values.multiplierMax,
      wagerMin: values.wagerMin,
      wagerMax: values.wagerMax,
      takeProfitLimit: values.takeProfitLimit,
      stopLossLimit: values.stopLossLimit,
      flatFeePercent: values.flatFeePercent,
      pnlFeePercent: values.pnlFeePercent,
      fundingFeePercent: values.fundingFeePercent,
      fundingFeeTimeframe: values.fundingFeeTimeframe,
    };
    await dispatch(createAsset(requestBody));
    await dispatch(getAssets());
    handleOk();
  };

  const requiredLabel = (text: string) => (
    <div>
      {text} <span style={{ color: '#ff4d4f' }}>*</span>
    </div>
  );

  return (
    <Modal title='Add asset' open={isOpenModal} onCancel={handleCancel} footer={null} styles={modalStyles}>
      <Form onFinish={onSubmit} className={styles.form} requiredMark={false} form={form}>
        <div className={styles.main}>
          <div className={styles.item}>
            <CustomSelect
              label={requiredLabel('Asset name')}
              name='symbol'
              placeholder='Enter asset name'
              options={[
                { value: 'BTCUSD', label: 'BTCUSD' },
                { value: 'ETHUSD', label: 'ETHUSD' },
                { value: 'TEST', label: 'TEST' },
              ]}
              rules={[{ required: true, message: 'Please enter asset name' }]}
            />
            <InputField
              label={requiredLabel('Display name')}
              name='name'
              rules={[{ required: true, message: 'Please enter display name' }]}
              placeholder='Enter display name'
            />
          </div>
          <div className={styles.item}>
            <DoubleInputField
              label={requiredLabel('Wager (min/max)')}
              name1='wagerMin'
              name2='wagerMax'
              rules1={[{ required: true, message: 'Please enter wager min' }]}
              rules2={[{ required: true, message: 'Please enter wager max' }]}
              placeholder1='Min'
              placeholder2='Max'
            />
            <DoubleInputField
              label={requiredLabel('Multiplier (min/max)')}
              name1='multiplierMin'
              name2='multiplierMax'
              rules1={[{ required: true, message: 'Please enter multiplier min' }]}
              rules2={[{ required: true, message: 'Please enter multiplier max' }]}
              placeholder1='Min'
              placeholder2='Max'
            />
          </div>
          <div className={styles.item}>
            <InputField
              label={requiredLabel('Limit (take profit)')}
              name='takeProfitLimit'
              rules={[{ required: true, message: 'Please enter limit (take profit)' }]}
              placeholder='Enter limit'
            />
            <InputField
              label={requiredLabel('Limit (stop loss)')}
              name='stopLossLimit'
              rules={[{ required: true, message: 'Please enter limit (stop loss)' }]}
              placeholder='Enter limit'
            />
          </div>
          <div className={styles.item}>
            <InputField
              label={requiredLabel('Flat Fee')}
              name='flatFeePercent'
              rules={[{ required: true, message: 'Please enter Flat fee' }]}
              placeholder='0.00%'
            />
            <InputField
              label={requiredLabel('PNL Fee')}
              name='pnlFeePercent'
              rules={[{ required: true, message: 'Please enter PNL fee' }]}
              placeholder='0.00%'
            />
          </div>
          <div className={styles.item}>
            <InputField
              label={requiredLabel('Funding Fee Percent')}
              name='fundingFeePercent'
              rules={[{ required: true, message: 'Please enter Funding Fee Percent' }]}
              placeholder='Enter %'
            />
            <InputField
              label={requiredLabel('Funding Fee Timeframe')}
              name='fundingFeeTimeframe'
              rules={[{ required: true, message: 'Please enter Funding Fee Timeframe' }]}
              placeholder='Enter N of hours'
            />
          </div>
          <div className={styles.item}>
            <ImageBlock />
            <StatusBlock status={status} onToggle={handleToggle} />
          </div>
        </div>
        <div className={styles.footer}>
          <Button key='cancel' onClick={handleCancel} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button key='ok' type='primary' htmlType='submit' className={styles.okButton}>
            Add asset
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
