import InputField from '../../../../../components/InputField/InputField';

import styles from './PasswordBlock.module.scss';

interface PasswordBlockProps {
  onGeneratePassword: () => void;
}

export default function PasswordBlock({ onGeneratePassword }: PasswordBlockProps) {
  return (
    <div className={styles.passwordBlock}>
      <div className={styles.field}>
        <InputField
          label='Password'
          name='password'
          rules={[{ required: true, message: 'Please enter your password' }]}
          placeholder='Input password'
          type='password'
        />
      </div>
      <div className={styles.text} onClick={onGeneratePassword}>
        Generate password
      </div>
    </div>
  );
}
